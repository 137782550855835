import React, { Component } from 'react'

class HeroSection extends Component {
  duration = 1000

  constructor(props) {
    super(props)

    this.state = {
      layers: [],
      transforms: [],
      mobile: false,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onResize)

    if(window.innerWidth <= 1025) {
      this.setState({
        mobile: true,
        layers: this.getLayers(this.props.headline, true, this.props.html),
      })
    }
    else {
      this.setState({
        layers: this.getLayers(this.props.headline, false, this.props.html),
      })
    }
  }

  onResize = () => {
   if(window.innerWidth <= 1025 && !this.state.mobile) {
     this.setState({
       mobile: true,
       layers: this.getLayers(this.props.headline, true, this.props.html),
     })
   } else if(window.innerWidth > 1025 && this.state.mobile) {
     this.setState({
       mobile: false,
       layers: this.getLayers(this.props.headline, false, this.props.html),
     })
   }
  }

  getLayers = (headline, isMobile, html) => {
    let path = '/images'
    let renderHeadline = html ?
        <div style={{...styles.headlineWrapper, top: '25%'}}>
          <div id={'hero-highlight'} className={'hero-headline'} style={styles.headlineHtml} dangerouslySetInnerHTML={{ __html: headline }} />
        </div>
        :
        <div style={styles.headlineWrapper}>
          <div id={'hero-highlight'} style={styles.headline}>{headline}</div>
        </div>
    if (isMobile) {
      path = '/images/mobile'
    }
    return [
      {
        image2x: window.location.origin + `${path}/Hero_1@2x.png`,
        image: window.location.origin + `${path}/Hero_1.png`,
        amount: 0.7,
        expanded: false,
      },
      {
        image2x: window.location.origin + `${path}/Hero_2@2x.png`,
        image: window.location.origin + `${path}/Hero_2.png`,
        amount: 0.61,
        expanded: false,
      },
      {
        image2x: window.location.origin + `${path}/Hero_3@2x.png`,
        image: window.location.origin + `${path}/Hero_3.png`,
        amount: 0.52,
        expanded: false,
        children: renderHeadline,
      },
      {
        image2x: window.location.origin + `${path}/Hero_4@2x.png`,
        image: window.location.origin + `${path}/Hero_4.png`,
        amount: 0.43,
        expanded: false,
      },
      {
        image2x: window.location.origin + `${path}/Hero_5@2x.png`,
        image: window.location.origin + `${path}/Hero_5.png`,
        amount: 0.31,
        expanded: false,
      },
      {
        image2x: window.location.origin + `${path}/Hero_6@2x.png`,
        image: window.location.origin + `${path}/Hero_6.png`,
        amount: 0.19,
        expanded: false,
      },
      {
        image2x: window.location.origin + `${path}/Hero_7@2x.png`,
        image: window.location.origin + `${path}/Hero_7.png`,
        amount: 0,
        expanded: false,
      },
    ]
  }

  getLayerComponents = () => {
    const { layers, transforms } = this.state
    return layers.map((layer, index) => {
        if (layer.children) {
          return (
            <div style={{ ...styles.image, ...transforms[index]}}>
              <img style={styles.image} srcSet={layer.image + ' 1x, ' + layer.image2x + ' 2x'}/>
              {layer.children}
            </div>
          )
        }
        return <img style={{...styles.image, ...transforms[index]}} srcSet={layer.image + ' 1x, ' + layer.image2x + ' 2x'}/>
      }
    )
  }

  onScroll = () => {
    const { layers } = this.state

    const duration = window.innerHeight > this.duration ? this.duration : window.innerHeight

    const progress = window.pageYOffset / duration

    if (progress > 1) {
      return
    }

    const transforms = layers.map(layer => {
      const yOffset = Math.round(layer.amount * progress * duration)
      return {
        transform: `translate3d(0, ${yOffset}px, 0)`,
      }
    })

    this.setState({
      transforms,
    })
  }

  render() {
    const { layers } = this.state
    const { highlight } = this.props
    console.log(highlight)
    return (
      layers &&
      <div style={{position: 'relative', height: '100vh', maxHeight: 1000}}>
        {this.getLayerComponents()}
      </div>
    )
  }
}

const styles = {
  parallaxBanner: {
    height: 1000,
  },
  container: {
    position: 'relative',
  },
  headlineWrapper: {
    position: 'absolute',
    top: '20%',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  headline: {
    padding: 25,
    textTransform: 'uppercase',
    color: '#007589',
    fontSize: 38,
    fontFamily: 'Eveleth',
    fontWeight: 400,
    letterSpacing: 2,
    maxWidth: 755,
    textAlign: 'center',
  },
  headlineHtml: {
    padding: 25,
    fontSize: 35,
    fontFamily: 'Graphie',
    maxWidth: 755,
    textAlign: 'center',
  },
  image: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    willChange: 'transform',
    objectFit: 'cover',
  },
}

export default HeroSection
