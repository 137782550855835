import React, { useState } from 'react'
import { withRouter, Link } from 'react-router-dom'

const MegaMenuItem = ({ item, index }) => {
  const [hoveringButton, setHoveringButton] = useState(false)

  const _styles = styles(index, hoveringButton)

  const onClickButton = (e) => {
    if (item.itemUrl) {
      e.preventDefault()
      window.open(item.itemUrl, '__blank')
    }
  }

  let url = ''
  if (item.itemEntry.length) {
    url = `/${item.itemEntry[0].uri}`
  }

  const icon = item.icon?.[0]?.url

  return (
    <div style={_styles.container}>
      {icon?.length ? <img src={icon} style={_styles.icon} /> : null}
      <div style={_styles.title}>{item.heading}</div>
      <div style={_styles.text}>{item.blurb}</div>
      <Link
        to={url}
        onClick={(e) => onClickButton(e)}
        onMouseEnter={() => setHoveringButton(true)}
        onMouseLeave={() => setHoveringButton(false)}
        style={_styles.button}>
        {item.buttonText || 'Learn More'}
        <p style={_styles.caret} />
      </Link>
    </div>
  )
}

const styles = (index, hoveringButton) => {
  return {
    container: {
      width: '100%',
      paddingTop: 40,
      paddingBottom: 40,
      borderTop: index === 0 ? '' : '1px solid #C9C9C7',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {
      height: 50,
      width: 50,
      objectFit: 'cover',
    },
    title: {
      marginBottom: 20,
      color: '#4D4E56',
      fontSize: 22,
      fontFamily: 'Graphie',
      fontWeight: 300,
      textAlign: 'center',
    },
    text: {
      color: '#707073',
      fontSize: 14,
      fontFamily: 'Graphie',
      fontWeight: 400,
      textAlign: 'center',
      marginBottom: 20,
    },
    button: {
      position: 'relative',
      color: hoveringButton ? '#7FBAC4' : '#007589',
      fontSize: 12,
      textTransform: 'uppercase',
      fontFamily: 'Graphie',
      fontWeight: 500,
      letterSpacing: 1.6,
      cursor: 'pointer',
    },
    caret: {
      borderStyle: 'solid',
      borderColor: hoveringButton ? '#7FBAC4' : '#007589',
      borderWidth: '0 2px 2px 0',
      display: 'inline-block',
      padding: 4,
      transform: 'rotate(-45deg)',
      position: 'absolute',
      left: hoveringButton ? 'calc(100% + 15px)' : 'calc(100% + 5px)',
      top: 3,
      transition: 'left 0.25s',
    },
  }
}

export default withRouter(MegaMenuItem)
