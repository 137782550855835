import React, { Component } from 'react'
import { withSizes } from '@oos-studio/website-common-components'
import Radium from 'radium'
import { withRouter } from 'react-router-dom'
import Video from './Video'

class Story extends Component {
  state = {
    useEntry: false,
    useUrl: false,
  }

  componentDidMount() {
    this.setState({
      useEntry: this.props.story.buttonEntry?.length > 0,
      useLink:
        !(this.props.story.buttonEntry?.length > 0) &&
        this.props.story.buttonUrl,
    })
  }

  clickButton = (e, route) => {
    const { useEntry, useLink } = this.state
    if (!useEntry && useLink) {
      e.preventDefault()
      window.open(route, '_blank')
    }
  }

  render() {
    const { story, getStyle } = this.props
    const { clickButton } = this
    const { useEntry, useLink } = this.state

    const route = useEntry
      ? story.buttonEntry[0].slug
      : useLink
      ? story.buttonUrl
      : ''

    return (
      <div style={getStyle(styles.container)}>
        <div style={getStyle(styles.widthRestrict)}>
          {story.image[0]?.mimeType.includes('image/') && (
            <img
              style={getStyle(styles.img)}
              alt={'storyImg'}
              src={story.image[0].url}
            />
          )}
          {story.image[0]?.mimeType.includes('video/') && (
            <Video
              styles={getStyle(styles.img)}
              controls={story.controls}
              autoplay={story.autoplay}
              loop={story.loop}
              source={story.image[0].url}
              type={story.image[0]?.mimeType}
            />
          )}
          <div style={getStyle(styles.textWrapper)}>
            <div style={getStyle(styles.titleWrapper)}>
              <div style={styles.title}>{story.title}</div>
              <div style={styles.subtitle}>{story.subtitle}</div>
            </div>
            <div style={getStyle(styles.text)}>{story.text}</div>
            <button
              to={route}
              onClick={(e) => clickButton(e, route)}
              style={styles.button}>
              {story.buttonText}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    backgroundColor: '#f7f7f7',
    paddingLeft: 60,
    paddingRight: 60,
    paddingTop: 60,
    paddingBottom: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Graphie',
    md: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  widthRestrict: {
    maxWidth: 1200,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    sm: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  img: {
    maxHeight: 350,
    marginBottom: 0,
    sm: {
      marginBottom: 35,
    },
  },
  textWrapper: {
    marginLeft: 35,
    textAlign: 'unset',
    sm: {
      textAlign: 'center',
      marginLeft: 0,
    },
  },
  titleWrapper: {
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: '#C9C9C7',
    paddingBottom: 45,
    marginBottom: 45,
    width: 'fit-content',
    sm: {
      width: '100%',
    },
  },
  title: {
    color: '#007589',
    fontSize: 21,
    fontWeight: 400,
    marginBottom: 10,
  },
  subtitle: {
    color: '#898A8D',
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: 18,
    letterSpacing: 2,
  },
  divider: {
    backgroundColor: '#C9C9C7',
    width: '100%',
    height: 2,
    marginTop: 45,
    marginBottom: 45,
    sm: {
      marginTop: 35,
      marginBottom: 35,
    },
  },
  text: {
    color: '#4D4E56',
    maxWidth: 600,
    fontSize: 18,
  },
  button: {
    marginTop: 35,
    backgroundColor: '#007589',
    color: 'white',
    borderRadius: 6,
    borderWidth: 0,
    fontSize: 18,
    padding: 9,
    paddingLeft: 15,
    paddingRight: 15,
    width: 175,
    fontWeight: 300,
    fontFamily: 'Graphie',
    transition: 'background-color 0.35s ease-out',
    ':focus': {
      outline: 'none',
    },
  },
}

export default withRouter(withSizes(Radium(Story)))
