import React, { Component } from 'react'
import ContactUs from '../contactUs/ContactUs'
import './Home.css'
import $ from 'jquery'
import WhatWeDo from './whatWeDo/WhatWeDo'
import { gqlConnect } from 'react-connect-graphql-beta'
import ScrollMagic from '../../plugins/ScrollMagic'
import { withSplash } from '../hocs/withSplash'
import DocumentMeta from '../../common/DocumentMeta'
import OurApproach from './components/OurApproach'
import GridSection from '../common/GridSection'
import { withRouter } from 'react-router-dom'
import HeroSection from './components/HeroSection'
import { withSizes } from '@oos-studio/website-common-components'
import HTMLBlurb from '../common/HTMLBlurb'
import {
  getMeta,
  _title,
  _description,
  _image,
  parseKeywords,
  _keywords,
} from '../../common/defaultMeta'

class Home extends Component {
  state = {
    videoOpen: false,
    videoId: '',
    headerZIndex: 0,
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.controller = new ScrollMagic.Controller()

    let navbarLogo = $('#navbar-logo')

    navbarLogo.css('opacity', '0')
    navbarLogo.css('transition', 'opacity .5s 0s')

    new ScrollMagic.Scene({ offset: 50 })
      .on('enter', function () {
        navbarLogo.css('opacity', '1')
        navbarLogo.css('transition', 'opacity 0s 0.5s')
      })
      .on('leave', function () {
        navbarLogo.css('opacity', '0')
        navbarLogo.css('transition', 'opacity .5s 0s')
      })
      .addTo(this.controller)

    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    $('#splashScreen').hide()
    this.controller.destroy()

    let navbarLogo = $('#navbar-logo')

    navbarLogo.css('opacity', '1')
    navbarLogo.css('transition', 'opacity 0.5s 0s')

    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    const { headerZIndex } = this.state

    const st = window.pageYOffset || document.documentElement.scrollTop
    let arrows = $('.react-slideshow-container .nav')

    const height = window.innerHeight
    const scroll = window.scrollY
    const opacity = (height - scroll) / height

    arrows.css('opacity', opacity)

    if (st > window.innerHeight) {
      if (headerZIndex !== -1)
        this.setState({
          headerZIndex: -1,
        })
    } else {
      if (headerZIndex !== 0) {
        this.setState({
          headerZIndex: 0,
        })
      }
    }
  }

  mapWhoWeHelpToGrid = (items) => {
    return items.map((item) => {
      return {
        icon: item.solutionIcon,
        name: item.solutionName,
        url: item.solutionUrl,
        entryLink: item.entryLink,
      }
    })
  }

  mapWhatWeDo = (items) => {
    return items.map((item) => {
      return {
        title: item.serviceTitle,
        icon: item.icon[0].url,
        heading1: item.heading1,
        heading2: item.heading2,
        heading3: item.heading3,
        subheading1: item.subheading1,
        subheading2: item.subheading2,
        subheading3: item.subheading3,
        entryLink: item.entryLink,
      }
    })
  }

  mapCustomersToGrid = (items) => {
    return items.map((item) => {
      return {
        icon: item.customerImage,
        name: null,
        url: item.customerUrl,
        entryLink: item.entryLink,
      }
    })
  }

  render() {
    const { mapCustomersToGrid, mapWhoWeHelpToGrid, mapWhatWeDo } = this
    const { gql } = this.props
    const {
      ourApproachHeading,
      ourApproachStates,
      homepageSolutionsHeading,
      valueProposition,
      homepageSolutions,
      customersHeading,
      customers,
      seoDescription,
      seoImage,
      seoKeywords,
      serviceBackgroundImage,
      servicesNew,
      serviceHeading,
      headlineNew,
      heading,
      services,
    } = gql

    const whoWeHelpItems = mapWhoWeHelpToGrid(homepageSolutions)

    return (
      <div className={'home-page'}>
        <DocumentMeta
          {...getMeta(
            _title,
            seoDescription || _description,
            seoImage.length > 0 ? seoImage[0].url : _image,
            seoKeywords.length > 0 ? parseKeywords(seoKeywords) : _keywords,
          )}>
          <HeroSection headline={headlineNew} html />
          <div style={{ zIndex: 1, top: -2, position: 'relative' }}>
            <HTMLBlurb
              content={valueProposition}
              alignment={'center'}
              theme={'dark'}
              backgroundColor={'#231F20'}
            />
            <WhatWeDo
              heading={serviceHeading}
              backgroundImage={serviceBackgroundImage}
              items={mapWhatWeDo(servicesNew)}
            />
            <OurApproach
              heading={ourApproachHeading}
              states={ourApproachStates}
            />
            {whoWeHelpItems?.length ? (
              <GridSection
                heading={homepageSolutionsHeading}
                items={whoWeHelpItems}
              />
            ) : null}
            <GridSection
              containerStyles={customersContainerStyles}
              itemStyles={customersItemStyles}
              style={'simple'}
              heading={customersHeading}
              items={mapCustomersToGrid(customers)}
            />
            <ContactUs />
          </div>
        </DocumentMeta>
      </div>
    )
  }
}

const customersContainerStyles = {
  container: {
    paddingBottom: 70,
  },
  gridContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    marginTop: 0,
    md: {
      marginTop: 0,
    },
    xxs: {
      marginTop: 0,
    },
  },
  heading: {
    textAlign: 'center',
  },
}

const customersItemStyles = {
  container: {
    width: 400,
    height: 200,
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 25,
    backgroundColor: 'transparent',
    paddingBottom: 25,
    xs: {
      paddingLeft: 25,
      paddingRight: 25,
      paddingTop: 25,
      paddingBottom: 25,
    },
    xxs: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 25,
      paddingBottom: 25,
      width: 250,
    },
  },
  image: {
    width: 'auto',
    maxWidth: 350,
    height: 'auto',
    maxHeight: 150,
    sm: {
      height: '100%',
      width: '100%',
      objectFit: 'contain',
    },
    xs: {
      height: '100%',
      width: '100%',
      objectFit: 'contain',
    },
    xxs: {
      height: '100%',
      width: '100%',
      objectFit: 'contain',
    },
  },
}

export default gqlConnect('home')(withRouter(withSplash(withSizes(Home))))
