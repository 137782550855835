import React, { useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { withSizes } from '@oos-studio/website-common-components'

const SimpleNavList = ({ list, display, getStyle }) => {
  const [activeHoverIndex, setActiveHoverIndex] = useState(-1)
  const _styles = styles(display, list.length)

  return (
    <div
      style={{ ...getStyle({ ..._styles.container, xxs: { fontSize: 13 } }) }}>
      {list.map((item, index) => {
        return (
          <Link
            to={`/${item.uri}`}
            onMouseEnter={() => setActiveHoverIndex(index)}
            onMouseLeave={() => setActiveHoverIndex(-1)}
            key={index}
            style={{
              ..._styles.item,
              color: 'rgb(0, 117, 137)',
              backgroundColor:
                activeHoverIndex === index
                  ? 'rgba(217, 216, 214, 0.3)'
                  : 'transparent',
            }}>
            {item.title}
          </Link>
        )
      })}
    </div>
  )
}

const styles = (display, numItems) => {
  return {
    container: {
      marginTop: 0,
      width: '100%',
      height: display ? numItems * 46 + 24 : 0,
      transition: 'height 0.25s',
      color: '#007589',
      fontSize: 15,
      letterSpacing: 2,
      textTransform: 'uppercase',
      fontFamily: 'Graphie',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      whiteSpace: 'nowrap',
      backgroundColor: 'rgba(255,255,255,0.85)',
      backdropFilter: 'blur(50px)',
      '-webkit-backdrop-filter': 'blur(50px)',
    },
    item: {
      paddingTop: 12,
      paddingBottom: 12,
      width: '100%',
      paddingLeft: 32,
      paddingRight: 32,
      // opacity: display ? 1 : 0,
      // transition: 'opacity 0.15s',
      textDecoration: 'none',
    },
  }
}

export default withRouter(withSizes(SimpleNavList))
