export const _title = 'Custom App Development'
export const _description = 'Premium apps for mobile, web, and TV by expert full-stack developers and creative problem-solvers.'
export const _image = 'https://s3.us-east-2.amazonaws.com/cdn.www.oos-studio.com/prod/oosMountains_PreviewImage.jpg'
export const _keywords = 'React.JS, React, Node.JS, Node, Custom, Development, Software, Web, Apps, Mobile, TV, Website, Business, Consulting, IoT, OTT, Digital, Transformation, Technology, Change, Cloud, Strategy, Design, OTT 2.0, Web 3.0, Process'

export const parseKeywords = (keywords) => {
  let keywordString = ''
  keywords.forEach((key, index) => {
    if (index < keywords.length - 1) {
      keywordString += `${key.keywordTitle},`
    } else {
      keywordString += key.keywordTitle
    }
  })
  return keywordString
}

export const getMeta = (title = _title, description = _description, image = _image, keywords = _keywords) => {
  return {
    title,
    description,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: keywords,
      },
      itemProp: {
        title,
        description,
        image,
      },
      property: {
        'og:title': title,
        'og:type': 'website',
        'og:image': image,
        'og:site_name': 'oos',
        'og:description': description,
        'og:url': window.location.href,
        'fb:app_id': '2317018421941218',
      }
    },
    auto: {
      ograph: true
    }
  }
}
