import React, { useState, useEffect } from 'react'
import { gqlConnect } from 'react-connect-graphql-beta'
import { useDispatch } from 'react-redux'
import { setShowNavbar } from '../../navigation/actions'
import { landscapeLogoOverPrimary as LandscapeLogoOverPrimary } from '../../globals/logos/Logos'
import NavigationBarItem from './NavigationBarItem'
import { withRouter, Link } from 'react-router-dom'
import { scrollToContactForm } from '../../../utils/ScrollToContactForm'

const NavigationBarDesktop = ({ gql, history }) => {
  const [isHoveringButton, setIsHoveringButton] = useState(false)
  const { navigationBarItems } = gql.data[0]
  const _styles = styles(isHoveringButton)

  let lastScrollToTop = 0

  const dispatch = useDispatch()

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  const onScroll = () => {
    if (window.pageYOffset > 50) {
      let st = window.pageYOffset || document.documentElement.scrollTop
      if (lastScrollToTop > 0) {
        if (st < lastScrollToTop) {
          dispatch(setShowNavbar(true))
        } else {
          dispatch(setShowNavbar(false))
        }
      }
      lastScrollToTop = st <= 0 ? 0 : st
    } else {
      dispatch(setShowNavbar(true))
    }
  }

  return (
    <div id={'navbar'} style={_styles.container}>
      <div style={_styles.logoWrapper}>
        <Link to={'/'} style={_styles.logoLink}>
          <LandscapeLogoOverPrimary />
        </Link>
      </div>
      {navigationBarItems.map((item, index) => {
        return <NavigationBarItem key={index} item={item} />
      })}
      <div
        onMouseEnter={() => setIsHoveringButton(true)}
        onMouseLeave={() => setIsHoveringButton(false)}
        onClick={() => scrollToContactForm(history)}
        style={_styles.contactButtonWrapper}>
        Let's Talk
      </div>
    </div>
  )
}

const styles = (isHoveringButton) => {
  return {
    container: {
      position: 'fixed',
      top: 0,
      height: 65,
      width: '100%',
      zIndex: 10000,
      backgroundColor: '#6AE1C6',
      display: 'flex',
      alignItems: 'center',
      paddingTop: 13,
      // paddingBottom: 13,
      paddingLeft: 32,
      paddingRight: 32,
    },
    logoWrapper: {
      flex: 1,
      paddingBottom: 13,
    },
    logoLink: {
      textDecoration: 'none',
    },
    itemsWrapper: {},
    contactButtonWrapper: {
      cursor: 'pointer',
      border: 'solid white 1px',
      borderRadius: 8,
      paddingTop: 5,
      paddingBottom: 5,
      marginBottom: 13,
      paddingLeft: 12,
      paddingRight: 12,
      marginLeft: 20,
      transition: 'background-color 0.35s ease-out, color 0.35s ease-out',
      backgroundColor: isHoveringButton ? 'white' : 'transparent',
      color: isHoveringButton ? '#4D4E56' : 'white',
      fontSize: 18,
      fontFamily: 'Graphie',
    },
    caret: {
      border: 'solid white',
      borderWidth: '0 2px 2px 0',
      display: 'inline-block',
      padding: 4,
      transform: 'rotate(45deg)',
      marginTop: 13,
      marginLeft: 10,
    },
  }
}

export default withRouter(gqlConnect('navigationBar')(NavigationBarDesktop))
