import React, { Component, useState } from 'react'
import { ListPage, ImageRowItem } from '@oos-studio/website-common-components'
import BottomScrollListener from 'react-bottom-scroll-listener'
import { MoonLoader } from 'react-spinners'
import { gqlConnect } from 'react-connect-graphql-beta'
import queries from '../../_gql'
import gqlTag from 'graphql-tag'
import { withRouter, Link } from 'react-router-dom'
import CategoryPicker from '../blog/CategoryPicker'
import { setNavbarBackground } from '../navigation/actions'
import { connect } from 'react-redux'
import { withSplash } from '../hocs/withSplash'
import { getMeta } from '../../common/defaultMeta'
import DocumentMeta from '../../common/DocumentMeta'

function ListItemBody(props) {
  const { date, title, align, uri, xs } = props

  const _align = xs ? 'left' : align

  const [buttonHover, setButtonHover] = useState(false)

  const style = styles.listItemBody
  return (
    <div align={_align}>
      <span style={style.date}>{date}</span>
      <div style={style.divider} />
      <Link style={style.title} to={uri}>
        <span style={{...style.title, marginBottom: 0}}>{title.toUpperCase()}</span>
      </Link>
      <div>
        <Link
          onMouseEnter={() => setButtonHover(true)}
          onMouseLeave={() => setButtonHover(false)}
          to={uri}
          style={buttonHover ? style.buttonHover : style.button}>
          READ
        </Link>
      </div>
    </div>
  )
}

const headerStylesSmall = {
  title: {
    letterSpacing: '15px',
    fontSize: 28,
    marginLeft: 25,
    marginRight: 25,
  },
  titleShadow: {
    transform: 'translateX(2.5px)',
  },
}

const headerStylesLarge = {
  title: {
    letterSpacing: '25px',
    fontSize: 46,
    marginLeft: 50,
    marginRight: 50,
  },
  titleShadow: {
    transform: 'translateX(4px)',
  },
}

class BlogListPage extends Component {
  state = {
    loadingItems: false,
    items: [],
    loadedAllItems: false,
    small: false,
    overImage: -1,
    search: '',
  }

  componentDidMount() {
    const { setNavbarBackground } = this.props
    setNavbarBackground(true)
    this.getListItems()
    this.onResize()
    window.addEventListener('resize', this.onResize)
  }

  componentWillUnmount() {
    const { setNavbarBackground } = this.props
    setNavbarBackground(false)
    window.removeEventListener('resize', this.onResize)
  }

  onResize = () => {
    if (window.innerWidth <= 576 && !this.state.small) {
      this.setState({
        small: true,
      })
    } else if (window.innerWidth > 576 && this.state.small) {
      this.setState({
        small: false,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { search } = this.state
    if (prevState.search !== search) {
      this.getListItems()
    }
  }

  clearSearch = () => {
    this.setState({
      loadedAllItems: false,
      items: [],
      search: '',
    })
  }

  setSearch = (category) => {
    this.setState({
      loadedAllItems: false,
      items: [],
      search: `articleCategories:${category.slug}`,
    })
  }

  getListItems = () => {
    const { gql } = this.props
    const { items, loadedAllItems, loadingItems, search } = this.state
    if (loadedAllItems || loadingItems) {
      return
    }
    const limit = 5
    const query = queries.find((q) => q.id === 'articleFeed')
    this.setState({
      loadingItems: true,
    })
    gql
      .query({
        query: gqlTag`${query.query}`,
        variables: {
          limit,
          offset: items.length,
          search,
        },
      })
      .then((result) => {
        const newItems = query.mapToProps(result)
        if (newItems.length < limit) {
          this.setState({
            loadedAllItems: true,
          })
        }
        this.setState({
          items: items.concat(newItems),
          loadingItems: false,
        })
      })
  }

  hoverStyles = {
    imageWrapperHoverStyles: {
      transform: 'scale(1.0275)',
      filter: 'drop-shadow(3px 3px 10px rgba(0, 0, 0, 0.05))',
    },
    imageHoverStyles: {
      transform: 'scale(1)',
    },
  }

  render() {
    const { title, asset, blpDescription: description } = this.props.gql
    const { history } = this.props
    const { items, loadingItems, small } = this.state
    const headerStyles = small ? headerStylesSmall : headerStylesLarge
    return (
      <div style={styles.container}>
        <DocumentMeta {...getMeta(title, description, asset[0].url)}>
          <BottomScrollListener offset={600} onBottom={this.getListItems} />
          <ListPage
            header={{
              title,
              backgroundAsset: asset[0],
              styles: {
                backgroundAsset: {
                  zIndex: -1,
                },
                container: {
                  height: 550,
                },
                text: {
                  zIndex: 1,
                },
                title: {
                  fontFamily: 'Eveleth',
                  color: '#007589',
                  transform: 'translateY(-48px)',
                  ...headerStyles.title,
                  marginBottom: 0,
                },
                subTitle: {
                  display: 'none',
                },
                titleShadow: {
                  color: '#6AE1C6',
                  ...headerStyles.titleShadow,
                },
              },
            }}
            styles={styles}
            renderAboveList={() => (
              <CategoryPicker
                allClicked={this.clearSearch}
                categoryClicked={this.setSearch}
              />
            )}
            renderBelowList={() =>
              loadingItems ? (
                <div style={styles.loadingSection}>
                  <MoonLoader
                    color={'#0F7588'}
                    css={'margin: 0 auto; height: auto; width: auto'}
                  />
                </div>
              ) : null
            }
            list={{
              items,
              renderItems: [
                (props) => (
                  <Link to={props.uri} style={{ textDecoration: 'none' }}>
                    <ImageRowItem
                      {...this.hoverStyles}
                      styles={styles.imageRowItem}
                      alignImage={'left'}
                      {...props}
                      renderBody={(xs) => (
                        <ListItemBody
                          {...props}
                          history={history}
                          align={'left'}
                          xs={xs}
                        />
                      )}
                    />
                  </Link>
                ),
                (props) => (
                  <Link to={props.uri} style={{ textDecoration: 'none' }}>
                    <ImageRowItem
                      {...this.hoverStyles}
                      styles={styles.imageRowItem}
                      alignImage={'right'}
                      {...props}
                      renderBody={(xs) => (
                        <ListItemBody
                          {...props}
                          history={history}
                          align={'right'}
                          xs={xs}
                        />
                      )}
                    />
                  </Link>
                ),
              ],
            }}
          />
        </DocumentMeta>
      </div>
    )
  }
}

const styles = {
  container: {},
  loadingSection: {
    width: '100%',
    paddingTop: 50,
    paddingBottom: 50,
    justifyContent: 'center',
    backgroundColor: 'white',
    display: 'flex',
  },
  listItemBody: {
    date: {
      color: '#898A8D',
      fontFamily: 'Graphie',
      fontWeight: 400,
      letterSpacing: 2,
      fontSize: 14,
      verticalAlign: 'top',
    },
    divider: {
      height: 1,
      width: 170,
      backgroundColor: 'lightGrey',
      marginTop: 13,
      marginBottom: 13,
    },
    title: {
      textDecoration: 'none',
      fontSize: 26,
      color: '#007589',
      fontFamily: 'Graphie',
      fontWeight: 300,
      marginBottom: 15,
      display: 'inline-block',
      pointer: 'cursor',
    },
    button: {
      border: '1px solid #6AE1C6',
      backgroundColor: 'transparent',
      fontFamily: 'Graphie',
      fontWeight: 400,
      letterSpacing: 2,
      fontSize: 14,
      color: '#898A8D',
      lineHeight: 1.5,
      borderRadius: '0.25rem',
      textDecoration: 'none',
      paddingTop: '0.375rem',
      paddingBottom: '0.375rem',
      paddingLeft: '0.75rem',
      paddingRight: '0.75rem',
      transition:
        'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    },
    buttonHover: {
      border: '1px solid #6AE1C6',
      backgroundColor: '#6AE1C6',
      fontFamily: 'Graphie',
      fontWeight: 400,
      letterSpacing: 2,
      fontSize: 14,
      color: 'white',
      lineHeight: 1.5,
      borderRadius: '0.25rem',
      textDecoration: 'none',
      paddingTop: '0.375rem',
      paddingBottom: '0.375rem',
      paddingLeft: '0.75rem',
      paddingRight: '0.75rem',
      transition:
        'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    },
  },
  imageRowItem: {
    container: {
      marginBottom: 35,
    },
    imageColumn: {
      minHeight: 275,
      height: 'auto',
      width: '49%',
      xs: {
        height: 300,
      },
    },
    imageWrapper: {
      transform: 'scale(1)',
      borderRadius: 4,
      transition: 'transform 1s cubic-bezier(.19,1,.22,1)',
    },
    image: {
      cursor: 'pointer',
      transform: 'scale(1.125)',
      transition: 'transform 1s cubic-bezier(.19,1,.22,1)',
      xs: {
        height: '100%',
      },
    },
  },
  contentContainer: {
    padding: 25,
    minHeight: 'calc(100vh + 100px)',
    maxWidth: 800,
  },
}

const mapDispatchToProps = {
  setNavbarBackground,
}

export default connect(
  null,
  mapDispatchToProps,
)(gqlConnect('page')(withRouter(withSplash(BlogListPage))))
