import React, { useState } from 'react'
import { withRouter, Link } from 'react-router-dom'
const SimpleNavList = ({ list, display }) => {
  const [activeHoverIndex, setActiveHoverIndex] = useState(-1)
  const _styles = styles(list.length, display)

  return (
    <>
      <div style={_styles.hoverBuffer} />
      <div className={'simple-list'} style={_styles.container}>
        {list.map((item, index) => {
          return (
            <Link
              to={`/${item.uri}`}
              onMouseEnter={() => setActiveHoverIndex(index)}
              onMouseLeave={() => setActiveHoverIndex(-1)}
              key={index}
              style={{
                ..._styles.listItemWrapper,
                color: 'rgb(0, 117, 137)',
                backgroundColor:
                  activeHoverIndex === index
                    ? 'rgba(217, 216, 214, 0.3)'
                    : 'transparent',
              }}>
              {item.title}
            </Link>
          )
        })}
      </div>
    </>
  )
}

const styles = (numItems, display) => {
  return {
    hoverBuffer: {
      position: 'absolute',
      top: 22,
      left: 0,
      right: 0,
      height: 23,
      zIndex: 1000,
      backgroundColor: 'transparent',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      whiteSpace: 'nowrap',
      height: display ? numItems * 46 + 24 : 0,
      paddingTop: display ? 12 : 0,
      paddingBottom: display ? 12 : 0,
      transition: 'height 0.25s',
      position: 'absolute',
      top: 46,
      left: 0,
      zIndex: 1000,
      color: '#007589',
      fontSize: 15,
      letterSpacing: 2,
      textTransform: 'uppercase',
      fontFamily: 'Graphie',
      overflow: 'hidden',
      borderRadius: 8,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      backgroundColor: 'rgba(255,255,255,0.85)',
      backdropFilter: 'blur(50px)',
      '-webkit-backdrop-filter': 'blur(50px)',
    },
    listItemWrapper: {
      paddingTop: 12,
      paddingBottom: 12,
      width: '100%',
      paddingLeft: 24,
      paddingRight: 24,
      opacity: display ? 1 : 0,
      transition: 'opacity 0.15s',
      textDecoration: 'none',
    },
  }
}

export default withRouter(SimpleNavList)
