import React from 'react'
import {default as ReactDocMeta} from 'react-document-meta'

const DocumentMeta = (props) => {
  const appendedTitle = `oos | ${props.title}`
  const finalProps = {
    ...props,
    title: appendedTitle,
    meta: {
      ...props.meta,
      property: {
        ...props.meta?.property,
        'og:title': appendedTitle,
      },
      itemProp: {
        ...props.meta?.itemProp,
        title: appendedTitle
      }
    }
  }
  return <ReactDocMeta {...finalProps} />
}

export default DocumentMeta
