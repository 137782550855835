import React, { useState } from 'react'
import { withRouter, Link } from 'react-router-dom'

const MegaMenuItem = ({ item, column, rowSize, isLastRow, history }) => {
  const [hovering, setHovering] = useState(false)

  const _styles = styles(column, rowSize, isLastRow, hovering)

  const onClick = (e) => {
    e.preventDefault()
    if (item.itemUrl) {
      window.open(item.itemUrl, '__blank')
    } else if (item.itemEntry.length) {
      history.push(`/${item.itemEntry[0].uri}`)
    }
  }

  const icon = item.icon?.[0]?.url

  return (
    <div
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onClick={onClick}
      style={{
        ..._styles.container,
        paddingTop: icon?.length ? 15 : 25,
      }}>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: icon?.length ? 'flex-start' : 'center',
          alignItems: 'center',
        }}>
        {icon?.length ? (
          <img src={item.icon[0].url} style={_styles.icon} />
        ) : null}
        <div>
          <div style={_styles.title}>{item.heading}</div>
          <div style={_styles.text}>{item.blurb}</div>
        </div>
      </div>
      <div style={_styles.button}>
        {item.buttonText || 'Learn More'}
        <p style={_styles.caret} />
      </div>
    </div>
  )
}

const styles = (column, rowSize, isLastRow, hovering) => {
  return {
    container: {
      cursor: 'pointer',
      width: 376,
      height: 281,
      paddingLeft: 60,
      paddingRight: 60,
      paddingTop: 15,
      paddingBottom: 25,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderLeft: column % 3 === 1 ? '1px solid #C9C9C7' : '',
      borderRight:
        column % 3 === 1 && column !== rowSize - 1 ? '1px solid #C9C9C7' : '',
      borderBottom: isLastRow ? '' : '1px solid #C9C9C7',
    },
    icon: {
      height: 75,
      width: 75,
      objectFit: 'cover',
    },
    borderSide: {
      height: 'calc(100% - 75px)',
      alignSelf: 'center',
      width: 1,
      backgroundColor: '#C9C9C7',
    },
    title: {
      marginBottom: 20,
      color: '#4D4E56',
      fontSize: 25,
      fontFamily: 'Graphie',
      fontWeight: 300,
      textAlign: 'center',
      lineHeight: 1.25,
    },
    text: {
      color: '#707073',
      fontSize: 14,
      fontFamily: 'Graphie',
      fontWeight: 400,
      textAlign: 'center',
    },
    button: {
      marginTop: 20,
      position: 'relative',
      color: hovering ? '#7FBAC4' : '#007589',
      fontSize: 14,
      textTransform: 'uppercase',
      fontFamily: 'Graphie',
      fontWeight: 500,
      letterSpacing: 1.6,
      textDecoration: 'none',
    },
    caret: {
      borderStyle: 'solid',
      borderColor: hovering ? '#7FBAC4' : '#007589',
      borderWidth: '0 2px 2px 0',
      display: 'inline-block',
      padding: 4,
      transform: 'rotate(-45deg)',
      position: 'absolute',
      left: hovering ? 'calc(100% + 15px)' : 'calc(100% + 5px)',
      top: 4,
      transition: 'left 0.25s',
    },
  }
}

export default withRouter(MegaMenuItem)
