import React, { Component } from 'react'
import { Row } from 'reactstrap'
import { gqlConnect } from 'react-connect-graphql-beta'

class CategoryPicker extends Component {
  state = {
    selectedIndex: -1,
  }

  renderCategoryItems = () => {
    const { gql, categoryClicked } = this.props
    const { categories } = gql
    const { selectedIndex } = this.state
    if (categories) {
      return categories.map((category, index) => (
        <div key={category.id} style={styles.item} onClick={() => {
          this.setState({selectedIndex: index});
          categoryClicked(category)
        }}>
          <span>{category.title}</span>
          <div style={{...styles.underline, width: selectedIndex === index ? '100%' : '0px'}}/>
        </div>
      ))
    }
    return null
  }

  render() {
    const { allClicked } = this.props
    const { selectedIndex } = this.state
    return (
      <div style={styles.container}>
        <Row style={styles.row}>
          <div style={styles.item} onClick={() => { this.setState({ selectedIndex: -1 }); allClicked() }}>
            <span>All</span>
            <div style={{...styles.underline, width: selectedIndex === -1 ? '100%' : '0px' }}/>
          </div>
          {this.renderCategoryItems()}
        </Row>
      </div>
    )
  }
}

CategoryPicker.defaultProps = {
  categoryClicked: () => {},
  allClicked: () => {},
}

const styles = {
  container: {
    padding: 15,
    paddingBottom: 30,
    marginTop: 5,
    marginBottom: 20,
  },
  item: {
    fontFamily: 'Graphie',
    fontSize: 14,
    letterSpacing: 2,
    color: '#898A8D',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    width: 'auto',
    paddingTop: 5,
    paddingBottom: 5,
    margin: 15,
    marginBottom: 0,
    cursor: 'pointer',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  underline: {
    height: 2,
    backgroundColor: '#6AE1C6',
    transition: 'width 0.3s',
    margin: 'auto',
  }
}

export default gqlConnect('categoryPicker')(CategoryPicker)
