import React from 'react'
import '../../../redactor.css'
import { setStyleClasses } from '../../../utils/HTMLTags'
import MegaMenuItem from './MegaMenuItem'
import AnimateHeight from 'react-animate-height'

const MegaMenu = ({ data, display }) => {
  const ROW_SIZE = 3
  const _styles = styles(display)

  const menu = data[0]

  const createRows = (data) => {
    let rows = []
    let row = []
    data.forEach((i) => {
      row.push(i)
      if (row.length === ROW_SIZE) {
        rows.push(row)
        row = []
      }
    })
    if (row.length) {
      rows.push(row)
    }
    return rows
  }

  const rows = createRows(menu.item)

  return (
    <AnimateHeight
      className={'mega-menu'}
      height={display ? 'auto' : 0}
      style={{
        ..._styles.container,
        pointerEvents: display ? 'auto' : 'none',
      }}>
      <div style={_styles.content}>
        <div className={'content-block'} style={_styles.heading}>
          <div
            style={_styles.html}
            dangerouslySetInnerHTML={{
              __html: setStyleClasses(menu.blurb, true, false),
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}>
          {rows.map((row, index) => {
            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}>
                {row.map((item, _index) => {
                  return (
                    <MegaMenuItem
                      key={_index}
                      item={item}
                      column={_index}
                      rowSize={row.length}
                      isLastRow={index === rows.length - 1}
                    />
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </AnimateHeight>
  )
}

const styles = (display) => {
  return {
    container: {
      position: 'absolute',
      right: 0,
      top: 65,
      left: 0,
      backgroundColor: 'rgba(255,255,255,0.85)',
      transition: 'opacity 0.15s',
      opacity: display ? 1 : 0,
      overflow: 'hidden',
      justifyContent: 'center',
      borderBottom: 'solid #C9C9C7 1px',
      backdropFilter: 'blur(50px)',
      '-webkit-backdrop-filter': 'blur(50px)',
    },
    content: {
      width: 1200,
      height: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: 35,
    },
    heading: {
      width: '100%',
      padding: 20,
      paddingTop: 0,
    },
    html: {
      textAlign: 'center',
    },
    grid: {
      position: 'relative',
      display: 'grid',
    },
  }
}

export default MegaMenu
